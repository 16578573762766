import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

// import Category from "../pages/Category.jsx";
// import Main from "../content/Main.jsx";
// import Search from "../components/AC-Search/Search";
import { handleMobileOrDesktop } from "../redux/actions/mainActions.js";
//  import WishList from "../components/AC-WishList/WishListItem";
// import CompareItems from "../components/AC-Compare/CompareItems.jsx";
// import ProductPage from "../components/AC-ProductPage/ProductPage";
// import Messaging from "../components/AC-Messaging/Messaging.jsx";
import {
  categoryUnmountAction,
  categoryFetchFlagAction
} from "../redux/actions/menuActions";

/* import PromotionComponent from "../components/AC-Advertising/PromotionComponent"; */
/* import WishListMobile from "../components/AC-WishList/components/WishListMobile"; */
import StaticContent from "../components/AC-StaticPages/StaticContent";
import PromotionBar from "../components/AC-Advertising/PromotionBar.jsx";
import SessionTimeout from "../components/AC-Popup/SessionTimeout.jsx";

const Main = lazy(() => import("../content/Main.jsx"));
const Search = lazy(() => import("../components/AC-Search/Search"));
const Category = lazy(() => import("../pages/Category.jsx"));
const ProductPage = lazy(() =>
  import("../components/AC-ProductPage/ProductPage")
);
/* const Login = (
  <Async load={import("../components/AC-LoginBox/Login.jsx")} />
); */
const Footer = lazy(() => import("../components/AC-Footer/Footer"));
const MenuTopBarCat = lazy(() => import("./MenuTopBarCat.jsx"));
const ShippingPopup = lazy(() =>
  import("../components/AC-Popup/ShippingPopup.jsx")
);
/* const NavMenu = () => <Async load={import("./NavMenu.jsx")} />; */
/* const Compare = () => <Async load={import("./Compare.jsx")} />; */

export default function MenuCore(props) {
  const { component } = props;

  const dispatch = useDispatch();

  const [currentScreenWidth, setCurrentScreenWidth] = useState(
    window.innerWidth
  );

  const programNameState = useSelector(
    state => state.loginReducer.programName,
    shallowEqual
  );

  const securityTokenState = useSelector(
    state => state.loginReducer.securityToken,
    shallowEqual
  );

  /*   const discountRateState = useSelector(
    state => state.mainReducer.discountRate,
    shallowEqual
  ); */

  const discountBannerTextState = useSelector(
    state => state.loginReducer.discountBannerText,
    shallowEqual
  );
  const discountMoreDetailsHeaderState = useSelector(
    state => state.loginReducer.discountMoreDeatilsHeader,
    shallowEqual
  );
  const discountMoreDetailsTextState = useSelector(
    state => state.loginReducer.discountMoreDeatilsText,
    shallowEqual
  );

  useEffect(() => {
    const handleResize = () => {
      setCurrentScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (component === "home") {
      window.digitalData.page.pageInfo.pageName = `CHX:Checks Store`;
      window.digitalData.page.category.pageType = "Home";
      window._satellite.track("home_page");
    }
    if (component !== "stores") {
      dispatch(categoryFetchFlagAction(true));
    }
  }, [component]);

  const renderPromotionBar = () => {
    /*  if (programNameState === "005021") {
      return <PromotionBar />;
    } */

    if (
      /* discountRateState &&  */ (discountBannerTextState,
      discountMoreDetailsHeaderState,
      discountMoreDetailsTextState)
    ) {
      return (
        <PromotionBar
          /*  discountRate={discountRateState} */
          discountBannerText={discountBannerTextState}
          discountMoreDetailsHeader={discountMoreDetailsHeaderState}
          discountMoreDetailsText={discountMoreDetailsTextState}
        />
      );
    }

    return null;
  };

  let isMobile;
  const mobileSize = 768;
  useEffect(() => {
    if (currentScreenWidth > mobileSize) {
      isMobile = false;
    } else {
      isMobile = true;
    }
    dispatch(handleMobileOrDesktop({ isMobile, currentScreenWidth }));
  }, [currentScreenWidth]);

  const customerPropsState = useSelector(
    state => state.loginReducer.customerProps,
    shallowEqual
  );

  useEffect(() => {
    if (customerPropsState && customerPropsState.length > 0) {
      let found = false;
      const customerId = customerPropsState.find(p => p.key === "customerId");
      if (customerId) {
        const customerIdValue = customerId.value;
        if (customerIdValue) {
          found = true;
          window.digitalData.profileInfo.customerID = customerIdValue;
          window.digitalData.profileInfo.visitorStatus =
            "Authenticated Visitor";
        }
      }
      if (found === false) {
        window.digitalData.profileInfo.customerID = "";
        window.digitalData.profileInfo.visitorStatus = "Unknown Visitor";
      }
    } else {
      window.digitalData.profileInfo.customerID = "";
      window.digitalData.profileInfo.visitorStatus = "Unknown Visitor";
    }
  }, [customerPropsState]);

  const handleWhatComponentToShowHere = props => {
    if (component === "category") {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <Category>{renderPromotionBar()}</Category>
        </Suspense>
      );
    } else if (component === "product") {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <ProductPage>{renderPromotionBar()}</ProductPage>
        </Suspense>
      );
    } /*  else if (component === "login") {
      return <Login />;
    } */ /*  else if (component === "aboutcomponent") {
      return <AboutComponent />;
    }  */ else if (component === "search") {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <Search {...props}>{renderPromotionBar()}</Search>
        </Suspense>
      );
    } /* else if (component === "wishlist") {
      return <WishList />;
    } else if (component === "compareitems") {
      return <CompareItems />;
    } else if (component === "cookiepage") {
      return <CookiePage />;
    } else if (component === "messages") {
      return <Messaging />;
    } */ else if (component === "staticContent") {
      return <StaticContent />;
    } else {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <Main>{renderPromotionBar()}</Main>
        </Suspense>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="header-container">
        {securityTokenState ? (
          <Suspense fallback={<div>Loading...</div>}>
            <SessionTimeout />
          </Suspense>
        ) : null}
        <Suspense fallback={<div>Loading...</div>}>
          <MenuTopBarCat />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <ShippingPopup />
        </Suspense>
        {/*      <PromotionComponent /> */}
        {/*   <Compare /> */}
        {/*   <WishListMobile /> */}
      </div>

      {handleWhatComponentToShowHere(props)}

      <Suspense fallback={<div>Loading...</div>}>
        <Footer />
      </Suspense>

      {/* <MailchimpSub /> */}
    </React.Fragment>
  );
}
