import {
  FETCHING_MENU_SUCCESS,
  FETCHING_MENU_FAILURE,
  FETCHING_BY_STYLE_MENU_SUCCESS,
  CATEGORY_UNMOUNT,
  CATEGORY_FETCH_FLAG_SET,
  SET_STORES_NAVCAT,
  featuredMenuActions,
  SET_FEATURED_PRODUCTS,
  SET_DISCOUNT_RATE
} from "../types.js";

import { store } from "../../index";

import menuData from "../../prefetchdata/menu.json";

import {
  MENU_EXD_FETCH_LINK,
  MENU_FETCH_LINK,
  BY_STYLE_MENU_FETCH_LINK,
  TEMP_MENU_FETHC_LINK,
  FEATURED_MENU_LINK
} from "../links.js";

import { fetchCategoryFromDirectUrl } from "./categoryActions.js";

import {
  changeEntryState,
  setSitedownForMaintenanceAction
} from "./mainActions";

import { allFirstLettersCapitalize } from "../../functions/capitalize";
import { LINK_DISTRIBUTION, VID } from "../../project-config.js";

export const fetchingMenuSuccess = (json, category) => {
  return {
    type: FETCHING_MENU_SUCCESS,
    payload: json
  };
};

export const setStoresNavCatAction = navCat => ({
  type: SET_STORES_NAVCAT,
  payload: navCat
});

export const fetchingByStyleSuccess = (json, category) => {
  return {
    type: FETCHING_BY_STYLE_MENU_SUCCESS,
    payload: json
  };
};

export const fetchFeaturedMenuAction = () => {
  return dispatch => {
    let featuredChecks = {};

    const featuredChecksUrl = cid =>
      `${LINK_DISTRIBUTION}/uservices/1.0.2/category-page/${VID}/cid/${cid}/lang/en/&sortci=topsellers%20asc&q=${Date.now()}`;

    /*    let featuredMenu = {};
    dispatch({
      type: featuredMenuActions.REQUEST_FEATURED_MENU
    });
    fetch(FEATURED_MENU_LINK)
      .then(res => res.json())
      .then(json => {
        featuredMenu = JSON.stringify(json);
        return json;
      })
      .then(json => {
        dispatch({
          type: featuredMenuActions.SUCCESS_FEATURED_MENU,
          payload: json
        });
        return json;
      })
      .then(json => {
        const foundCid = json.cid; */

    const foundCid = 200095;
    if (foundCid) {
      dispatch({ type: SET_FEATURED_PRODUCTS, payload: "fetching" });
      fetch(featuredChecksUrl(foundCid))
        .then(res => res.json())
        .then(json2 => {
          if (json2?.[1]?.items?.[0]?.discount) {
            dispatch({
              type: SET_DISCOUNT_RATE,
              payload: Number(json2[1].items[0].discount)
            });
          }
          dispatch({ type: SET_FEATURED_PRODUCTS, payload: json2 });
        })
        .catch(err => {
          console.error("error fetching featured products", err);
          dispatch({ type: SET_FEATURED_PRODUCTS, payload: "error" });
        });
    }
    /*      })
      .catch(err => {
        console.error(err);
        dispatch({ type: featuredMenuActions.FAILURE_FEATURED_MENU });
      }); */
  };
};

export const fetchingMenuFailure = error => ({
  type: FETCHING_MENU_FAILURE,
  payload: error
});

export const categoryUnmountAction = () => ({
  type: CATEGORY_UNMOUNT
});

export const categoryFetchFlagAction = payload => ({
  type: CATEGORY_FETCH_FLAG_SET,
  payload: payload
});

/*export const fetchMenu = arg => {
  return dispatch => {
    dispatch(fetchingMenuSuccess(menuData));
    if (
      arg.pathname.includes("shop") &&
      !arg.pathname.includes("login") &&
      !arg.pathname.includes("wishlist") &&
      !arg.pathname.includes("about")
    ) {
      dispatch(fetchCategoryFromDirectUrl());
    } else {
      return 1;
    }
  };
};
*/

export const fetchMenu = (arg, language) => {
  let entryState = store.getState().mainReducer.entryState;
  return dispatch => {
    if (entryState) {
      dispatch(changeEntryState());
    }
    fetch(MENU_FETCH_LINK.replace("$LANGUAGE", language))
      .then(res => {
        if (res.status == "503") {
          const text = res.text().then(text => {
            console.log("text", text);
            dispatch(setSitedownForMaintenanceAction(text));
            throw "Site is down for maintenance";
          });
        }
        return res.json();
      })
      .then(json => {
        dispatch(setSitedownForMaintenanceAction(false));
        dispatch(fetchingMenuSuccess(json));
      })
      .then(() => {
        if (
          arg.pathname.includes("shop") &&
          !arg.pathname.includes("login") &&
          !arg.pathname.includes("wishlist") &&
          !arg.pathname.includes("about") &&
          !arg.pathname.includes("shop/stores")
        ) {
          //dispatch(categoryFetchFlagAction(false));
          //dispatch(fetchCategoryFromDirectUrl());
        } /*  else if (arg.pathname.includes("stores")) {
          let link = arg.pathname.split("stores/")[1];
          if (link.includes("-")) {
            link = link.split("-").join(" ");
          }
          link = allFirstLettersCapitalize(link);
        } else {
          return 1;
        } */
      })
      .catch(error => {
        dispatch(fetchingMenuFailure(error));
      });
  };
};
