import { VID, LINK_DISTRIBUTION, PREVIEW } from "../project-config.js";
const PAGE_ITEMS_COUNT = 24;
/*export const MENU_FETCH_LINK = `${LINK_DISTRIBUTION}/subcat.ajx?vid=${VID}&cname=Shop&iu=$LANGUAGE`;*/

export const ACCOUNT_MESSAGES_LINK = `${LINK_DISTRIBUTION}/myaccountmessage.ajx?vid=${VID}`; // &type=2 send

export const MENU_FETCH_LINK = `${LINK_DISTRIBUTION}/uservices/1.0.2/menu/${VID}/category/menu1/lang/$LANGUAGE/q/${Date.now()}/`;

export const MENU_EXD_FETCH_LINK = `${LINK_DISTRIBUTION}/subcat.ajx?vid=${VID}&cid=72180&longdesc=all&showqty=yes`;
export const BY_STYLE_MENU_FETCH_LINK = `${LINK_DISTRIBUTION}/subcat.ajx?vid=${VID}&cid=72167&level=1&longdesc=all&showqty=yes&_=1570632812521`;

export const BASKET_LINK = (lang, vid = null) =>
  `${LINK_DISTRIBUTION}/uservices/1.0.2/basket/${
    vid || VID
  }/lang/${lang}/q/${Date.now()}/`;

export const CATEGORY_FETCH_LINK = (
  cid,
  lang,
  lat = null,
  lng = null,
  distance = null
) =>
  `${LINK_DISTRIBUTION}/uservices/1.0.2/category-page/${VID}/cid/${cid}/lang/${lang}/q/${Date.now()}/&pageItemsCount=${PAGE_ITEMS_COUNT}${
    lat && lng && distance
      ? `&a=1&lat=${lat}&long=${lng}&distance=${distance}`
      : ``
  }`;

export const SEARCH_FETCH_LINK = (keyword, language) =>
  `${LINK_DISTRIBUTION}/uservices/1.0.2/search-page/${VID}/keyword/${keyword}/page/1/lang/${language}/q/${Date.now()}/`;

export const AHEAD_SEARCH = keyword =>
  `${LINK_DISTRIBUTION}/typeaheadsearch.ajx?vid=${VID}&pagetile=AdvancedSearchUsingSolrNew&iu=EN&q=${keyword}&q=${Date.now()}`;

export const CATEGORY_PAGING_FETCH_LINK = (
  cid,
  lang,
  page,
  queryString = null
) =>
  `${LINK_DISTRIBUTION}/uservices/1.0.2/category-paging/${VID}/cid/${cid}/lang/${lang}/page/${page}/showperpage/24/${
    queryString ? `?${queryString}` : ""
  }`;

export const SEARCH_PAGING_FETCH_LINK = (keyword, lang, page) =>
  `${LINK_DISTRIBUTION}/uservices/1.0.2/search-page/${VID}/keyword/${keyword}/page/${page}/lang/${lang}/`;

export const LOGIN_SIGNIN_LINK = `${LINK_DISTRIBUTION}/uservices/1.0.2/signin/${VID}/lang/$LANGUAGE/`;

export const LOGIN_CHECK_LINK = `${LINK_DISTRIBUTION}/uservices/1.0.2/login/${VID}/q/${Date.now()}/`;

export const FEATURED_FETCH_LINK = `${LINK_DISTRIBUTION}/uservices/1.0.2/category-page/${VID}/cid/59522/lang/$LANGUAGE/q/${Date.now()}/`;

export const FEATURED_MENU_LINK = `${LINK_DISTRIBUTION}/uservices/1.0.2/menu/${VID}/category/FeaturedProducts/lang/en/q/${Date.now()}/`;

export const CATEGORY_FEATURED_LINK = `${LINK_DISTRIBUTION}/uservices/1.0.2/category-page/${VID}/cid/$cid/lang/$LANGUAGE/&sortci=topsellers%20asc&q=${Date.now()}`;

export const LOGOUT_LINK = `${LINK_DISTRIBUTION}/uservices/1.0.2/signout/${VID}/q/${Date.now()}/`;

export const GET_ITEM_LINK = `${LINK_DISTRIBUTION}/uservices/1.0.2/product-list/${VID}/iid/$ITEMREPLACE/lang/$LANGUAGE/q/${Date.now()}/`;

export const GET_ATTIRIBUTE_LINK = `${LINK_DISTRIBUTION}/uservices/1.0.2/variant/${VID}/aid/$ATTIRIBUTEREPLACE/lang/$LANGUAGE/q/${Date.now()}/?iu=$LANGUAGE`;

export const GET_ID_PRODUCT_LINK = `${LINK_DISTRIBUTION}$PRODUCT?tpt=json_$LANGUAGE&q=${Date.now()}`;

export const GET_CURRENCY_ID = `${LINK_DISTRIBUTION}/uservices/1.0.2/currency/${VID}/q/${Date.now()}/`;

export const GET_CURRENCY_INFO = `${LINK_DISTRIBUTION}/getcurrency.ajx?vid=${VID}&currencyid=$CURRENCY`;
export const GET_CURRENCY_LIST = `${LINK_DISTRIBUTION}/getcurrency.ajx?vid=${VID}`;

// export const GET_PRICE_INVENTORY = `${LINK_DISTRIBUTION}/uservices/1.0.2/priceinventory/${VID}/iid/$PRODUCT/lang/$LANGUAGE/`;

export const ADD_TO_CART = `${LINK_DISTRIBUTION}/uservices/1.0.2/product-page/${VID}/iid/$PRODUCT/lang/$LANGUAGE/q/${Date.now()}/`;
export const ADD_TO_SUPP_CART = (vid, itemId, lang) =>
  `${LINK_DISTRIBUTION}/uservices/1.0.2/product-page/${
    vid || VID
  }/iid/${itemId}/lang/${lang}/`;

export const LIFT_REVIEW_FLAG = (vid, biid) =>
  `${LINK_DISTRIBUTION}/reviewreorderitem.ajx?vid=${vid || VID}&biid=${biid}`;

export const GET_DELIVERY_OPTIONS = `${LINK_DISTRIBUTION}/getdeliveryoptions.ajx`;

export const ITEM_REVIEW = `${LINK_DISTRIBUTION}/uservices/1.0.2/product-review/`;

//export const GET_SUPPLIER_INFO = `${LINK_DISTRIBUTION}/uservices/1.0.2/suppliers/${VID}/iid/$PRODUCT/lang/$LANGUAGE/`;

export const GET_LANGUAGES = `${LINK_DISTRIBUTION}/uservices/1.0.2/language/${VID}/`;

export const TEST_SEARCH = `https://search.avetti.ca/catalog/ssearch/$KEYWORD.html?tptm=json_$LANGUAGE`;

export const GET_PERSONALIZED_QUESTIONS = (id, biid = null) =>
  `${LINK_DISTRIBUTION}/personalizedquestion.html?vid=${VID}&iid=${id}${
    biid ? `&biid=${biid}` : ""
  }&act=init&executedAction=addItem&executeActionStatus=false&tpt=json_en&q=${Date.now()}`;

export const POST_PERSONALIZED_QUESTIONS = id =>
  `${LINK_DISTRIBUTION}/personalizedquestion.html?vid=${VID}${
    id ? `&iid=${id}` : ""
  }`;

export const GET_LOGO_CLIPARTS = () =>
  `${LINK_DISTRIBUTION}/getlogoclipart.ajx?vid=${VID}&q=${Date.now()}/`;

export const UPLOAD_LOGO = quesDetailID =>
  `${LINK_DISTRIBUTION}/uploadlogo.html?vid=${VID}&logoimage=logoimage_${quesDetailID}&qcode=question${quesDetailID}&tpt=json_en&q=${Date.now()}`;

export const getUrlToCheckBox = ({
  vid,
  languageState,
  biid = null,
  addedItemId = null
}) => {
  let languageParam =
    languageState == "en" ? `&ml=${languageState}` : `&iu=${languageState}`;
  const addedItem = addedItemId
    ? `&${biid ? "updatedItem" : "addedItem"}=${addedItemId}`
    : "";
  return `${LINK_DISTRIBUTION}/basket.html?vid=${
    vid || VID
  }${languageParam}${addedItem}`;
};
