import React, { useEffect } from "react";
import history from "../../history.js";
import Content from "./Content.js";
import staticContents from "./StaticRoutes.js";
import "./StaticContent.css";

import { Helmet } from "react-helmet";

function StaticContent(props) {
  let link = history.location.pathname.split("/");
  if (link[link.length - 1] == "") {
    link = link[link.length - 2];
  } else {
    link = link[link.length - 1];
  }

  let indexLocation;

  let content = staticContents.find((content, index) => {
    if (content.url == link) {
      indexLocation = index;
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    if (content && window.digitalData) {
      if (content.url === "contact-us") {
        window.digitalData.page.category.pageType = "Customer Service";
        window.digitalData.page.category.primaryCategory = "Customer Service";
      } else {
        window.digitalData.page.category.pageType = "General Content Pages";
        window.digitalData.page.category.primaryCategory = "Policy";
      }

      window.digitalData.page.pageInfo.pageName = `CHX:${content.title} - Checks Store`;
      window._satellite.track("home_page");
    }
  }, [content]);

  let staticContent = Content.find(c => c.url === content.url);

  useEffect(() => {
    if (link) {
      window.scrollTo(0, 0);
    }
  }, [link]);

  return (
    <div className="static-content-container">
      <Helmet>
        <title>{content.title}</title>
        <meta name="description" content={content.description} />
      </Helmet>
      <div id="bd">
        <h1 className="static-title">
          {content.title}
          <div className="divider-gray-gradient"></div>
        </h1>

        <div dangerouslySetInnerHTML={{ __html: staticContent.content }} />
      </div>
    </div>
  );
}

export default StaticContent;
