import React, { useEffect, useRef, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import classes from "./SessionTimeout.module.css";
import Modal from "../AC-UI-Elements/Modal/Modal";
import { LINK_DISTRIBUTION, VID } from "../../project-config";
import { handleLogout } from "../../redux/actions/loginActions";

const timeOut = 14 * 60 * 1000;
const disappearTimeOut = 60 * 1000;
const SESSION_TIMEOUT_CID = 200687;

export default function SessionTimeout() {
  const dispatch = useDispatch();

  const securityToken = useSelector(
    state => state.loginReducer.securityToken,
    shallowEqual
  );

  const sessionTimerId = useRef();
  const disappearTimeoutId = useRef();
  const countdownTimerRef = useRef();

  const [modalOpen, setModalOpen] = useState(false);

  const [secondsRemaining, setSecondsRemaining] = useState(
    disappearTimeOut / 1000
  );

  const initTimer = () => {
    sessionTimerId.current = setTimeout(() => {
      setModalOpen(true);
    }, timeOut);
  };

  useEffect(() => {
    initTimer();
    console.log("sessionTimeout init", sessionTimerId.current);

    return () => {
      if (sessionTimerId.current) window.clearTimeout(sessionTimerId.current);
      console.log("sessionTimeout clear", sessionTimerId.current);
    };
  }, []);

  useEffect(() => {
    if (modalOpen) {
      const countDownDate = new Date().getTime() + disappearTimeOut;

      // If interval is not set, set it
      if (!countdownTimerRef.current) {
        // Update the count down every 1 second
        countdownTimerRef.current = setInterval(() => {
          // Get today's date and time
          const now = new Date().getTime();

          // Find the time passed between now and the count down date
          const difference = countDownDate - now;

          // seconds

          const seconds = Math.ceil((difference % (1000 * 60)) / 1000);

          setSecondsRemaining(seconds);

          // If the count down is finished
          if (difference < 0) {
            if (countdownTimerRef.current)
              window.clearInterval(countdownTimerRef.current);
            setSecondsRemaining(0);
          }
        }, 1000);
      }

      disappearTimeoutId.current = setTimeout(() => {
        setModalOpen(false);
        redirectToSessionTimeout();
      }, disappearTimeOut);
    }

    return () => {
      if (disappearTimeoutId.current)
        window.clearTimeout(disappearTimeoutId.current);
      console.log("disappearTimeout clear", disappearTimeoutId.current);
    };
  }, [modalOpen]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const redirectToSessionTimeout = () => {
    dispatch(handleLogout(securityToken));
    window.location.href = `${LINK_DISTRIBUTION}/store.html?vid=${VID}&cid=${SESSION_TIMEOUT_CID}`;
  };

  const handleCancelClicked = () => {
    redirectToSessionTimeout();
  };

  const handleContinueClicked = () => {
    fetch(`${LINK_DISTRIBUTION}/getlogin.ajx?vid=` + VID)
      .then(res => res.json())
      .then(json => {
        console.log("sessionTimeout renew", sessionTimerId.current);
        if (sessionTimerId.current) window.clearTimeout(sessionTimerId.current);
        sessionTimerId.current = null;
        if (disappearTimeoutId.current)
          window.clearTimeout(disappearTimeoutId.current);
        disappearTimeoutId.current = null;
        if (countdownTimerRef.current)
          window.clearInterval(countdownTimerRef.current);
        countdownTimerRef.current = null;
        setSecondsRemaining(disappearTimeOut / 1000);
        initTimer();
        handleClose();
      })
      .catch(err => console.log("error get login sessiontimeout", err));
  };

  return (
    <Modal noMinWidth={true} open={modalOpen}>
      <div className={classes.wrapper}>
        <h1>Your Session is about to timeout</h1>
        <p>
          Your session expires in {secondsRemaining} seconds, Hit continue if
          you want to extend your session.
        </p>
        <div className={classes.buttonsWrapper}>
          <button onClick={handleCancelClicked}>End Session</button>
          <button type="submit" onClick={handleContinueClicked}>
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
}
