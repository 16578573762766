import React, { useEffect } from "react";
import { setHTMLElementFixedPosition } from "../../../functions/Utilities";
import * as classes from "./Modal.module.css";

export default function Modal({ open, onClose, children, noMinWidth }) {
  useEffect(() => {
    setHTMLElementFixedPosition(open);
    return () => {
      setHTMLElementFixedPosition(false);
    };
  }, [open]);
  if (open) {
    return (
      <React.Fragment>
        <div
          className={`${classes.wrapper}${
            noMinWidth ? " " + classes.noMinWidth : ""
          }`}
        >
          {children}
        </div>
        <div
          className={classes.backdrop}
          onClick={() => {
            if (onClose) onClose();
          }}
        ></div>
      </React.Fragment>
    );
  } else return null;
}
