import React, { useEffect } from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import LinearLoading from "../components/AC-UI-Elements/LinearLoading/LinearLoading";
import MenuCore from "../header/MenuCore.jsx";

import { fetchDirectUrlGetItem } from "../redux/actions/productAction";

import { I18nContext } from "../i18n/index";

function Menu(props) {
  const { langCode } = React.useContext(I18nContext);
  const dispatch = useDispatch();
  const isLoading = useSelector(
    state => state.menuReducer.isLoading,
    shallowEqual
  );

  useEffect(() => {
    let count = 0;
    if (props.component == "product" && count == 0) {
      count++;

      let languageStorage = localStorage.getItem("language");
      if (!languageStorage) {
        localStorage.setItem("language", langCode);
        languageStorage = "en";
      }
      let countryStorage = localStorage.getItem("country");
      if (!countryStorage) {
        countryStorage = "en";
        localStorage.setItem("country", countryStorage);
      }
      console.info(
        "new url4---",
        window.location.pathname,
        langCode,
        languageStorage,
        countryStorage,
        props.component
      );

      console.info(
        "new url4--- CONTAINS",
        window.location.pathname,
        localStorage.getItem("language"),
        window.location.pathname.includes(
          `/${localStorage.getItem("language")}/`
        )
      );
      if (
        window.location.pathname.includes(
          `/${localStorage.getItem("language")}/`
        ) === false
      ) {
        dispatch(
          fetchDirectUrlGetItem(
            window.location.pathname,
            localStorage.getItem("language"),
            countryStorage
          )
        );
      } else {
        dispatch(
          fetchDirectUrlGetItem(
            window.location.pathname,
            langCode,
            countryStorage
          )
        );
      }
      /*   } */
    }
    return () => {
      count = 0;
    };
  }, [props.component, window.location.pathname, langCode]);

  if (isLoading) {
    return (
      <div>
        <LinearLoading />
      </div>
    );
  } else {
    return (
      <MenuCore
        searchedPage={props?.searchedPage}
        setSearchedPage={props?.setSearchedPage}
        component={props.component}
        {...props}
      />
    );
  }
}

export default Menu;
