import React, { useState } from "react";
import Modal from "../AC-UI-Elements/Modal/Modal";
import close_white from "../../assets/icons/close_white.svg";

export default function PromotionBar({
  offsetHeight,
  /* discountRate, */
  discountBannerText,
  discountMoreDetailsHeader,
  discountMoreDetailsText
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleSeeMoreDetailsClicked = () => {
    setModalOpen(true);
  };
  return (
    <>
      <Modal
        onClose={() => setModalOpen(false)}
        noFooter={true}
        open={modalOpen}
      >
        <div
          aria-modal="true"
          role="dialog"
          className="promotion-modal-wrapper"
        >
          <div className="main-category-modal-header">
            <h1>{discountMoreDetailsHeader}</h1>
            <img
              src={close_white}
              alt="close icon"
              onClick={() => setModalOpen(false)}
            ></img>
          </div>
          <div className="main-category-modal-content">
            <p>{discountMoreDetailsText}</p>
            <button
              type="button"
              autoFocus="true"
              onClick={() => setModalOpen(false)}
            >
              OK
            </button>
          </div>
        </div>
      </Modal>
      <div
        className="main-category-promotion"
        style={{ marginTop: offsetHeight }}
      >
        {discountBannerText}
        <button
          type="button"
          className="main-category--see-more-details"
          onClick={handleSeeMoreDetailsClicked}
        >
          See more details.
        </button>
      </div>
    </>
  );
}
