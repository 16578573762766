import {
  FETCH_BASKET_SUCCESS,
  FETCH_SUPPLIERS_BASKET_SUCCESS
} from "../types.js";

const initialState = {
  basket: {
    itemsCount: 0,
    products: [],
    quoteproducts: [],
    totalPriceProducts: 0,
    totalPriceQuoteProducts: 0
  },
  suppliersBasket: {
    itemsCount: 0,
    products: [],
    quoteproducts: [],
    totalPriceProducts: 0,
    totalPriceQuoteProducts: 0
  }
};

const setTotal = products => {
  let totalPrice = 0;
  products.map(({ price, qty }) => {
    totalPrice += price * qty;
  });
  return totalPrice;
};

const pileUpTheProducts = suppliers => {
  let piledUpProducts = {
    itemsCount: 0,
    products: [],
    quoteproducts: [],
    totalPriceProducts: 0,
    totalPriceQuoteProducts: 0
  };

  suppliers.forEach(supplier => {
    piledUpProducts.itemsCount +=
      supplier.products.length + supplier.quoteproducts.length;
    piledUpProducts.products.push(...supplier.products);
    piledUpProducts.quoteproducts.push(...supplier.quoteproducts);
    piledUpProducts.totalPriceProducts += setTotal(supplier.products);
    piledUpProducts.totalPriceQuoteProducts += setTotal(supplier.quoteproducts);
  });

  return piledUpProducts;
};

const menuReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BASKET_SUCCESS:
      return {
        ...state,
        basket: {
          ...state.basket,
          itemsCount: payload.products.reduce((a, product) => {
            if (product.childProduct === false) a += 1;
            return a;
          }, 0),
          products: payload.products,
          quoteproducts: payload.quoteproducts,
          totalPriceProducts: setTotal(payload.products),
          totalPriceQuoteProducts: setTotal(payload.quoteproducts)
        }
      };
    case FETCH_SUPPLIERS_BASKET_SUCCESS: {
      return {
        ...state,
        suppliersBasket: {
          ...pileUpTheProducts(payload)
        }
      };
    }
    default:
      return state;
  }
};

export default menuReducer;
