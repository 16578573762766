import { PROJECT_LINK, PREVIEW } from "../../project-config.js";

const content = [
  {
    url: "privacy-california",
    content: `<div class="privacy-california static-container article-post__body">
    <p><strong>Your California Rights</strong></p>
    <p>If you are a California resident, you have certain rights related to your California Personal Information. [You may exercise these rights free of charge except as otherwise permitted under applicable law.]</p>
    <ul>
    <li><strong>Section 100 Rights</strong>. You have the right to request that we disclose to you the specific species of personal information we have collected.</li>
    <br>
    <li><strong>Section 105 Rights</strong>. You have the right to request that we delete California Personal Information about you which we have collected from you.</li>
    <br>
    <li><strong>Section 110 Rights</strong>. You may request that we disclose to you:<ul>
    <li>the categories of California Personal Information we have collected about you;</li>
    <li>the categories of sources from which the California Personal Information is collected;</li>
    <li>our business or commercial purpose for collecting or selling California Personal Information;</li>
    <li>the categories of third parties with whom we share California Personal Information; and</li>
    <li>the specific pieces of information we have collected about you.</li>
    </ul>
    </li>
    <li><strong>Section 115 Rights</strong>. To the extent that we sell your California Personal Information within the meaning of the California Consumer Privacy Act or disclose such information for a business purpose, you may request that we disclose to you:<ul>
    <li>the categories of California Personal Information that we have collected about you;</li>
    <li>the categories of California Personal Information about you that we have sold within the meaning of the California Consumer Privacy Act and the categories of third parties to whom the California Personal Information was sold, by category or categories of personal information for each third party to whom the California personal information was sold; and</li>
    <li>the categories of California Personal Information about you that we disclosed for a business purpose.</li>
    </ul>
    </li>
    <li><strong>Right to Opt Out</strong>. In addition, we may sell your information within the meaning of the California Consumer Privacy Act. You have the right to opt out of such sales here.</li>
    </ul>
    <p>You may request to exercise these rights by:</p>
    <ul>
    <li>Completing our <a href="#" onclick="rightsRequest()">rights request form</a></li>
    <li>Emailing us at <a href="mailto:Privacy@deluxe.com?fsubject=Privacy%20Inquiry&amp;body=1.%09Request%20description%3A%0A2.%09Name%3A%0A3.%09Household%20Address%3A%0A4.%09Email%3A%0A5.%09Phone%20Number%3A%0A%0D%0A%0D%0AThank%20you.">Privacy@deluxe.com</a></li>
    <li>Calling us toll-free at 877-861-8985.</li>
    </ul>
    <p>[As required or permitted under applicable law, please note that we may take steps to verify your identity before granting you access to information or acting on your request to exercise your rights. We may limit our response to your exercise of the above rights as permitted under applicable law.] Subject to applicable law, we may not discriminate against you because of your exercise of any of the above rights, or any other rights under the California Consumer Privacy Act, including by:</p>
    <ul>
    <li>Denying you goods or services;</li>
    <li>Charging different prices or rates for goods or services, including through the use of discounts or other benefits or imposing penalties;</li>
    <li>Providing you a different level or quality of goods or services; or</li>
    <li>Suggesting that you will receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
    </ul>
                <p>If you are a California resident, the following provisions apply to our processing of information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household [subject to the California Consumer Privacy Act] (“California Personal Information”). For such residents, the provisions of this California Addendum prevail over any conflicting provisions of the Privacy Notice.</p>
    <p>Deluxe has collected the following categories of California Personal Information within the last 12 months:</p>
    <p>(A) Identifiers such as a real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, social security number, driver’s license number, passport number, or other similar identifiers.</p>
    <p>(B) Personal information described in subdivision (e) of Section 1798.80 (California Customer Records statute). This means any information that identifies, relates to, describes, or is capable of being associated with, a particular individual, including, but not limited to, his or her name, signature, social security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. </p>
    <p>(C) Characteristics of protected classifications under California or federal law.</p>
    <p>(D) Commercial information, including records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</p>
    <p>(E) Biometric information.</p>
    <p>(F) Internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding a consumer’s interaction with an Internet Web site, application, or advertisement.</p>
    <p>(G) Geolocation data.</p>
    <p>(H) Audio, electronic, visual, thermal, olfactory, or similar information.</p>
    <p>(I) Professional or employment-related information.</p>
    <p>(J) Education information, defined as information that is not publicly available personally identifiable information as defined in the Family Educational Rights and Privacy Act (20 U.S.C. section 1232g, 34 C.F.R. Part 99).</p>
    <p>(K) Inferences drawn from any of the information identified in this subdivision to create a profile about a consumer reflecting the consumer’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</p>
    <p>Within the last 12 months, within the meaning of the California Consumer Privacy Act, we have sold California Personal Information identified in the above categories A-K.</p>
    <p>Within the last 12 months, we have disclosed California Personal Information identified in the above categories (A)-(K) for our business purposes. To learn more about the categories of third parties with whom we share such information, please see <a href="https://www.deluxe.com/policy/">/policy</a>.  </p>
    <p>We obtain California Personal Information from a variety of sources. [These sources include: yourself, with respect to both online and offline interactions you may have with us or our service providers;  other entities with whom you transact; others with whom you maintain relationships who may deal with us on your behalf; the devices you use to access our websites, mobile applications, and online services; credit bureaus; identify verification and fraud prevention services; marketing and analytics providers; public databases; social media platforms; and others consistent with this Privacy Notice.]  Please see <a href="https://www.deluxe.com/policy/">/policy</a>.</p>
    <p>We use the California Personal Information we collect for the business purposes disclosed within this Privacy Notice. Please see <a href="https://www.deluxe.com/policy/">/policy</a>. Please note that the business purposes for which we may use your information include:</p>
    <ul>
    <li>Audits and reporting relating to particular transactions and interactions, including online interactions, you may have with us or others on our behalf;</li>
    <li>Detecting and protecting against security incidents, and malicious, deceptive, fraudulent or illegal activity, and prosecuting the same;</li>
    <li>Debugging to identify and repair errors in our systems;</li>
    <li>Short-term, transient use including contextual customization of ads;</li>
    <li>Providing services on our behalf or on behalf of another, including maintaining or servicing accounts, providing customer service, fulfilling transactions, verifying identity information, processing payments, and other services;</li>
    <li>Conducting internal research to develop and demonstrate technology; and</li>
    <li>Conducting activity to verify, enhance, and maintain the quality or safety of services or devices which we may own, control, or provide.</li>
    </ul>
    
             </div>`
  },
  {
    url: "terms-and-conditions",
    content: `<main><div class="static-container">



    <h2>Terms and Conditions</h2>

<h3>Terms</h3>
<p>Deluxe Financial Services, Inc. ("Deluxe") maintains the
www.orderpoint.deluxe.com site (the "Site"). Your access and use of
the Site, including any purchases made on the Site, is subject to
the following Terms and Conditions ("Terms and Conditions"). By
accessing and using the Site, you accept, without limitation or
qualification, the Terms and Conditions. IF YOU DO NOT AGREE TO THE
TERMS AND CONDITIONS, DO NOT ACCESS OR USE THIS SITE. DELUXE MAY AT
ANY TIME REVISE THE TERMS AND CONDITIONS BY UPDATING THIS POSTING.
YOU ARE BOUND BY ANY SUCH REVISIONS AND SHOULD THEREFORE
PERIODICALLY VISIT THIS PAGE TO REVIEW THE CURRENT TERMS AND
CONDITIONS TO WHICH YOU ARE BOUND.</p>

    <h2>A. Use of Site </h2>

    <p>1. You may download material displayed on the Site for non-commercial, personal use only, provided you do not remove or alter any trademark, copyright or other proprietary notices contained on the materials. You may not, however, distribute, modify, transmit, reuse, report, or use the contents of the Site for public or commercial purposes, including the text and images, without Deluxe's prior written permission.</p>

    <p>2. You should assume that everything you see or read on the Site is copyrighted unless otherwise noted and may not be used except as provided in the Terms and Conditions (and the text on the Site) without the prior written permission of Deluxe. Deluxe neither warrants or represents that your use of materials displayed on the Site will not infringe rights of any third parties.</p>

    <p>3. Deluxe makes no warranties or representations as to the accuracy or completeness of information and materials contained on the Site and Deluxe assumes no liability or responsibility for any inaccuracies, errors or omissions in the content on the Site.</p>

    <p>4. Your use and browsing of the Site is at your risk. Neither Deluxe nor any other party involved in creating, producing, maintaining or delivering the Site is liable for any direct, indirect, special, incidental, consequential or other damages of any nature or description arising out of your access to, or use of, the Site. Without limiting the foregoing, everything on the Site is provided to you "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. Please note that some jurisdictions may not allow the exclusion of implied warranties, so some of the above exclusions may not apply to you. Deluxe also assumes no responsibility, and shall not be liable for any damages to, or viruses that may infect, your computer equipment or other property on account of your access to, use of, or browsing on the Site or your downloading of any materials, data, text or images from the Site.</p>

    <p>5. Any communication or material you transmit to the Site by electronic mail or otherwise, including any data, questions, comments, suggestions, or the like is, and will be treated as, non-confidential and non-proprietary, except as required by law and our privacy policy. Anything you transmit or post may be used by Deluxe or its affiliates for any purpose permitted by law or our privacy policy, including but not limited to reproduction, disclosure, transmission, publication, broadcast and posting. Furthermore, Deluxe is free to use, without limitation or restriction, any ideas, concepts, know-how or techniques contained in any communication you send to or through the Site for any purpose whatsoever, including but not limited to developing, manufacturing and marketing products and services using such information.</p>

    <p>6. Images of people or products displayed on the Site are either the property of, or used with permission by, Deluxe. The use of these images by you, or anyone else authorized by you, is prohibited, except for use of the linking icon in accordance with the Deluxe Guidelines. Any unauthorized use of the images may violate applicable laws, including copyright laws, trademark laws, the laws of privacy and publicity.</p>

    <p>7. Deluxe has not reviewed all of the sites linked to the Site and is not responsible for the contents of any off-site pages or any other sites linked to the Site or the products and services offered by or through such Sites or the privacy, data security or other business practices of those owning or maintaining such sites. Your linking to, and access and use of, any other off-Site pages or other sites is at your own risk.</p>

    <p>8. You are prohibited from posting or transmitting to Deluxe or the Site any unauthorized or misappropriated information, materials or images or unlawful, threatening, libelous, defamatory, obscene, scandalous, inflammatory, pornographic, or profane information, materials or images or any information, material or image that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability, or otherwise violate any law. It is Deluxe's policy to fully cooperate with any law enforcement authorities or court order requesting or directing Deluxe to disclose the identity of anyone posting or transmitting any such information, materials or images.</p>

    <h2>B. Intellectual Property </h2>

    <p>Except as noted otherwise in the text of the Site, the trademarks, logos, service marks and all copyrightable materials displayed on the Site constitute intellectual property owned by Deluxe or which Deluxe has the right to use on the Site (the "Deluxe Intellectual Property"). Nothing contained on the Site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any Deluxe Intellectual Property displayed on the Site without the prior written permission of Deluxe. Unauthorized use of the Deluxe Intellectual Property displayed on the Site, or any other content on the Site, is strictly prohibited. Please be advised that Deluxe will aggressively enforce its intellectual property rights to the fullest extent of the law, including the seeking of criminal prosecution.</p>

    <h2>C. Purchases of Products </h2>

    <p>1. Payment for your order will occur only by debiting the account for which you place your check order. By your placement of the order, you authorize the debit through an automated clearing house (ACH) debit.</p>

    <p>2. Prices charged are F.O.B. our dock. State and local sales tax will be added to your order where applicable.</p>

    <p>3. All prices and products are subject to change, discontinuation and modification without notice or obligation. Shipping, handling, processing, and service charges may vary. All prices in U.S. dollars. Check with your financial institution to obtain pricing information. Your financial institution establishes the price you pay for your check order.</p>

    <p>4. We will replace any Deluxe manufactured product found to contain printing errors caused by Deluxe or to be defective in workmanship or materials, or offer you a refund if a replacement check is not satisfactory. While Deluxe takes care in the manufacture of such products, it is commercially impossible to detect all errors and imperfections. Therefore, no other warranty is given, and all affirmations, samples or models made or shown are for illustrative purposes only. THE ABOVE WARRANTY IS EXPRESSLY IN LIEU OF ALL OTHER WARRANTIES AND REPRESENTATIONS, EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN NO EVENT WILL DELUXE BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY NATURE OR DESCRIPTION DAMAGES RESULTING FROM ANY USE OR MISUSE OF ANY PRODUCT OR ANY ACT OR OMISSION BY DELUXE, NOR WILL DELUXE BE LIABLE FOR ANY BREACH OF WARRANTY (OR OTHER OBLIGATION BINDING UPON DELUXE) IN AN AMOUNT GREATER THAN THE PURCHASE PRICE OF THE PRODUCT ACTUALLY PAID TO DELUXE.</p>

    <h2>D.  Mandatory Arbitration Agreement, Class-Action Waiver, and Jury Waiver </h2>

    <p>1. Applicability of Arbitration Agreement.  You and Deluxe agree that all claims and disputes related in any way to use of this Site, or to products or services sold by Deluxe or distributed by or through Deluxe, will be resolved by individual arbitration before the American Arbitration Association ("AAA") under the Federal Arbitration Act ("FAA"), except that you and Deluxe are not required to arbitrate small claims court disputes as set forth in subsection (8.) below. To be clear: The phrase "all claims and disputes" also includes claims and disputes that arose between you and Deluxe before the effective date of these Terms.</p>

    <p>2. Waiver of Jury Trial.  You and Deluxe waive any Constitutional and/or statutory rights to a trial in court in front of a judge or a jury.</p>

    <p>3. Class Actions Waiver.  You and Deluxe agree all arbitrations will proceed on an individual basis, and waive any right to bring claims as part of a class action (either as a named-plaintiff or class member), and as part of any other proceeding where someone acts in a representative capacity of any kind.</p>

    <p>4. Authority of the Arbitrator. The arbitrator, and not any federal, state or local court or agency, shall have exclusive authority to resolve all disputes arising out of or relating to the interpretation, applicability, enforceability or formation of this Agreement, including, but not limited to any claim that all or any part of this Agreement is void or voidable. However, an arbitrator can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages).  The arbitrator's decision will be final except for a limited right of review under the FAA.</p>

    <p>5. Arbitration Procedure.  Either party may initiate arbitration with the AAA pursuant to its Consumer Arbitration Rules ("AAA Rules"), as modified by this Arbitration Agreement. The AAA Rules are available on the AAA's website www.adr.org, or by calling the AAA at (800) 778-7879.  You may choose to have the arbitration conducted by telephone, based on written submissions, or in person in the county where you live or at another mutually agreed location.</p>

    <p>6. Arbitration Fees and Payments.  Payment of all filing, administration and arbitrator fees will be governed by the AAA's applicable rules, however Deluxe will reimburse those fees to you in an amount up to $25,000 (though, in no event will Deluxe pay any attorneys' fees and/or costs you may incur).  Deluxe also waives its own right to seek attorneys' fees and costs in arbitration.</p>

    <p>7. Right to Opt-out. You may opt out of this arbitration agreement by notifying Deluxe in writing no later than 30 days after first becoming subject to this arbitration agreement. Your notice must include your name and address and an unequivocal statement that you want to opt out of this arbitration agreement. You must either mail your opt-out notice to this address: Deluxe Financial Services, LLC, ATTN: Privacy Program Office, 3660 Victoria Street North, Shoreview, MN 55126, or email the opt-out notice to privacyprogramoffice@deluxe.com. You also may reject any changes we make to this arbitration agreement in later versions of these Terms by sending Deluxe notice within 30 days of your notice of the change. If you do, the most recent version of the arbitration agreement to which you agreed before the change you rejected will apply.</p>

    <p>8. Small Claims Court. Notwithstanding the foregoing, either you or Deluxe may bring an individual action in small claims court in your county of residence or in Shoreview, Minnesota, if you meet the court's requirements.</p>

    <p>9. Severability. If the class action waiver is found to be illegal or unenforceable as to all or some parts of a dispute, then those parts will not be arbitrated but will proceed in court, with the rest of the dispute proceeding in arbitration.</p>

    <p>10. Arbitration Agreement Survival. This arbitration agreement will survive the termination of your relationship with Deluxe.</p>
</div></main>`
  },
  {
    url: "privacy-policy",
    content: `
<main>
   <div class="static-container">
    
     <h4>Deluxe Privacy Policy</h4> 
    
     <p>Effective Date: 06/09/2021 </p>
     <p>This Privacy Policy applies to the website or service owned and operated by Deluxe Financial Services Inc. and its subsidiaries and affiliates (referred to herein as “Deluxe,” “we,” “us” or “our”) from which it was accessed. The following information will help you understand how we collect, use, share and safeguard your personal information. It also describes your choices regarding use, access and correction of your personal information. </p>
      
      <h4>Information Collection & Use</h4>
      <p>In order to provide you with the products or services you have requested, Deluxe must collect personal information. At Deluxe, we intend to give you as much control as possible over your personal information.  </p>
      
      <p>The type of information we collect, use, or share may depend on the product or service you have with us. At times, we may request that you voluntarily supply identifying information for purposes such as receiving correspondence, registering on this website, making purchases, or participating in online surveys, forums, blogs, chat sessions, market research or contests/sweepstakes. If you elect to participate, we may require you to provide business information, including your name, mailing address, phone number, financial institution information, credit card information, or e-mail address. </p>
      
      <p>In certain areas of our website, we may collect information under your instruction such as employee information that you add to your business account and updated contact information. We will only use this information for the specific reason for which it is provided. When you submit business information to us, you understand and agree that we may access, store, and use your information to fulfill your requested services. We are committed to safeguarding and protecting your business information, and the personal information of your employees. </p>
      
      <p>We use the information collected about you or provided under your instruction to process orders and to provide a more personalized and value-added experience. We may also use your information to communicate with you about products, services, and future promotions. For the purposes of order verification and fraud prevention, we may exchange information about our customers internally and among our affiliates. Based upon the personally identifiable information you provide us, we may send you a welcoming email to verify your username and password. We will also communicate with you in response to your inquiries, to provide the services you request, and to manage your account. We will communicate with you by email or telephone, in accordance with your preferences. We may send you service-related announcements on rare occasions when it is necessary to do so. Generally, you may not opt out of these communications, which are not promotional in nature. If you do not wish to receive them, you have the option to deactivate your account. </p>
      
      <p>For testimonials, we obtain the customer's permission prior to posting their name along with their testimonial. We are not responsible for the personally identifiable information you choose to submit through testimonials. Information you provide will be safeguarded according to industry standards for security and confidentiality. </p>
      
      <h4>Information Sharing and Your Choices</h4> 
      <table class="terms-and-condition-table" border="1" cellspacing="0"><tbody>
      <tr><th>Reasons we can share your information</th>  <th>Does Deluxe Share?</th><th>  Can you limit this sharing?</th></tr>
      <tr><td>For our everyday business purposes - including billing, processing transactions, &amp; fulfilling orders</td> <td> Yes</td> <td>No</td></tr>
      <tr><td>For our marketing purposes - to offer products and services to grow your business</td><td> Yes </td><td>Yes</td></tr>
      <tr><td>For our affiliated companies to market to you </td><td>Yes </td><td>Yes</td></tr>
      <tr><td>For third parties to market to you on our behalf </td><td> No </td> <td>N/A</td></tr>
      </tbody></table>
      <p>Names and other personal information that are received by us through a financial institution are not shared with third parties except for the purpose of fulfilling our contractual requirements with financial institutions or as otherwise permitted by law.</p>
      
      <p>In some cases, we may use third-party service providers to assist in providing you with customer service, fulfilling your requested service and you agree that we may release information about you to them for these purposes. When we engage third-party suppliers, they are only provided the information required to perform those services, such as order fulfillment, payment processing, or fulfilling subscription services. They are expected to abide by their contractual obligations when conducting work for us and are prohibited from using your information for any other purpose. </p>
      
      <p>We provide you with the opportunity to opt out of receiving unsolicited marketing as a result of your transaction with us. If you no longer wish to receive special offers or promotional communications from us, you may edit your Account Profile or follow the unsubscribe instructions included in each communication. You may also express your optout choices by contacting customer service, as described below. </p>
      <p>Deluxe maintains policies and procedures to help ensure that consumer data is used appropriately and within the scope of applicable laws. Information is made available for fraud prevention and detection purposes and for authorized criminal or government investigations. This information is made available in order to protect consumers and financial services companies; these disclosures are permitted by law. Deluxe expresses our commitment to appropriate data use in our check-printing agreements with our financial institution customers.  </p>
      
      <p>In certain situations, we may be required to disclose personal information in response to lawful requests by public authorities, including to meet law enforcement requirements. We may also disclose your personal information as required by law, such as to comply with a subpoena or other legal process, when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request. </p>
      
      <p>We reserve the right to disclose information about you as required by law and when we believe that disclosure is necessary to protect our rights and/or comply with a judicial proceeding, court order, legal process served on our company. Our subpoena policy can be reviewed here. If we are involved in a merger, acquisition, dissolution or sale of all or a portion of its assets, we reserve the right to transfer your personal information. You will be notified via email and/or a prominent notice on our website of any change in ownership, uses of your personal information, and choices you may have regarding your personal information. </p>
      
      <h4>Security</h4>
      <p>We are committed to protecting your personal and business information. Security measures are in place on this website to protect against the loss, misuse, and alteration of the information under our control. We offer industry-standard security measures available through your web browser. When you are on this website, the sensitive information you provide such as credit card and financial information is encrypted with secure socket layer (SSL) technology and scrambled en route and decoded once it reaches us. Please remember that e-mail, which is different from the encrypted sessions above, is not secure. Therefore, we ask that you do not send sensitive information such as credit card or account numbers to us via an unsecured e-mail message. </p>
      
      <p>Information you provide will be safeguarded according to strict standards of security and confidentiality. We recognize and appreciate the importance of responsible use of the information you choose to provide. We contractually require that our vendors, consultants, suppliers, and contractors have appropriate security measures in place and abide by all contractual requirements regarding the security and the collection, use, and exchange of our customers' information. They are expected to abide by the terms of their contract when conducting work for us and are prohibited from using your personal information for any other purpose. </p>
      
      <p>We may retain your information for as long as your account is active or as needed to provide you services, comply with our legal obligations, resolve disputes and enforce our agreements. </p>
      
      <h4>Tracking Activity on our Website</h4>
      <p>In order to make your visit to our website as productive as possible, we track certain user activity. However, we want you to know that the tracking technology we use does not identify any personal information about you. It cannot retrieve data from your hard drive, pass on computer viruses, or capture any personal information about you, such as your name, address, phone number, or email address. We only know who you are if you tell us. </p>
      
      <p>As is true with most websites, we gather certain information automatically when individuals browse our site and store it in log files. This information may include internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, the files viewed on our site (e.g., HTML pages, graphics, etc.), operating system, date/time stamp, and/or clickstream data. We use a third-party tracking service that uses technology to track this non-personally identifiable information about visitors to our site in the aggregate. </p>
      
      <p>We and our partners use cookies or similar technologies to improve your shopping experience, analyze trends, administer the site, track users’ movements around the site, and gather demographic information about our user base as a whole. Cookies are small pieces of information that are stored by your web browser on your computer's hard drive. A cookie may contain information (such as a unique user session ID) that is used to track the pages of the websites that a user has visited. The information in cookies helps to serve you better by keeping track of your order when shopping on this site; it also helps us improve site design. We do not store sensitive information in your cookies. You can control the use of cookies at the individual browser level, but if you choose to disable cookies, it may limit your use of certain features or function on our website. </p>
      
      <p>We also partner with third parties to display advertising on our website or manage our advertising on other sites. Our third-party partner may use cookies or similar technologies in order to provide you advertising based upon your browsing activities and interests. If you wish to opt out of interest-based advertising click here. Please note that you will continue to receive generic ads. </p>
      
      <h4>Children Visiting Our Websites</h4>
      <p>This website is intended for use by adults. We do not knowingly collect or use information from children under the age of 13. If we become aware of any collection of personal information from children, we will delete it immediately.
      Other Websites Linked to this Site
      This website contains links to other sites that we may or may not own or control. We encourage you to be aware when you leave this site and to read the privacy policies and applicable terms of use posted on each and every website that collects personally identifiable information. This privacy policy applies only to information collected on this website. </p>
      
      
      <h4>Changes to Privacy Policy</h4>
      <p>By using this website, you consent to our posted Privacy Policy. If you do not agree with this Privacy Policy, please do not use this website. We reserve the right to modify this Privacy Policy at any time, so please review it frequently. Your continued use of this website following the posting of changes to these terms means that you accept these changes. </p>
      
      <p>We may change, modify or update our Privacy Policy at any time. Any changes to the privacy and security policy will be posted on this website and any other places we deem appropriate so that you are aware of the information we collect, as well as how we use and protect it. If we make material changes to this policy, we will notify you by email, or by means of a notice displayed on our web page prior to the changes taking effect. </p>
      
      <p>Periodically, our operations and business practices are reviewed for compliance with corporate policies and procedures governing the confidentiality of information. These reviews are conducted by internal staff who report directly to the Board of Directors, external auditing and accounting firms, and government regulators. Included in these self-assessments and examinations are reviews of the controls and safeguards described in our Privacy Policy. </p>
      
      <p>We are a BBB accredited business and complies with BBBOnLine standards.   </p>
      
      <h4>To Correct/Update/Access/Opt Out:</h4>
      <p>We are committed to ensuring that the information we obtain and use about our customers is accurate. You may access, correct, or request deletion of your personal information by updating your profile information online or by contacting us using one of the methods listed below. We will respond to your request within a reasonable timeframe, and our service representatives are trained to answer questions about and give you reasonable access to your personal information in our database. </p>
      
      <p>We will retain your information for as long as your account is active or as needed to provide you services and we will use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. If you wish to cancel your account or request that we no longer use your information to provide you services, contact us at the contact information listed below. </p>
      
      <h4>Contacting Us</h4>
      <p>If you have questions regarding this Privacy Policy, the practices of this website or your dealings with this website, please contact us at: </br></br>
      Privacy Program Office</br>
      3680 Victoria Street N.</br>
      Shoreview, MN 55126</br>
      Phone: 1-833-341-1813</br>
      Fax: 1-800-336-1112</br>
      E-mail: <a href="mailto:privacyprogramoffice@deluxe.com">privacyprogramoffice@deluxe.com</a></br></br>
      If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, <a href="https://feedback-form.truste.com/watchdog/request"> please contact our U.S.-based third-party dispute resolution provider (free of charge) here.</a> </p>
      </div> 
      `
  },
  {
    url: "accessibility",
    content: `<div class="static-container accessibility whitebox content-padding rounded clearfix">


<main>
  <div class="row-fluid">


            <h2>Accessibility at Deluxe</h2>


            <p></p>
            
            <p>Deluxe is committed to providing individuals with
                disabilities access to goods, services, and privileges offered
                on the website <a href="home.htm" class="ada-link">orderpoint.deluxe.com</a> and is partaking in
                ongoing efforts to improve the accessibility of <a href="home.htm" class="ada-link">orderpoint.deluxe.com</a>.
            </p>
            <p>If you have specific questions or concerns about the
                accessibility of this site or need assistance with using this
                site, please contact us. Please call 877-838-5287 or email <a href="mailto:accessibility@deluxe.com" class="ada-link">accessibility@deluxe.com</a>.

            </p>
            <p>When contacting us please specify the particular web
                page about which you have a question, want to submit a comment,
                or require assistance.</p>

            <p>We offer our customers guideline/raised-line checks,
                which are larger (8 1/8" x 3") with embossed guidelines that
                are easily felt. Raised-line checks are available in wallet or
                duplicate style.</p>

            <p>Screen reader applications<br>
                
                <p>The following browser and screen reader application
                    pairings are recommended for the best customer experience:<br>
                    <ul>
                        <li>Microsoft Internet Explorer and JAWS</li>
                        <li>Mozilla Firefox and NVDA</li>
                        <li>Chrome and TalkBack for Android devices</li>
                        <li>Safari and VoiceOver for iOS and macOS devices</li>
                    </ul>
                </p>
                
            </p>
            


        </div>
        
</div>`
  },
  {
    url: "contact-us",
    content: `<div class="static-container contact-us">
  <p>Questions? Our sales and service representatives are happy to assist you. Please call us at <a href="tel:+18885603939">1-888-560-3939</a>.

 </p>
 <p>We are available Monday to Friday between 7am-7pm CST.</p>

  </div>`
  }
];

export default content;
